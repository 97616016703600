/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

const mock = [
  {
    image: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img5.jpg',
    description:
      "Income/mortgage protection insurance can be a valuable tool for managing financial risks and ensuring financial stability in the event of a covered event. It is particularly important for those who have dependents or who have financial obligations that they cannot meet if they are unable to work.",
    desc:"It is important to carefully consider your needs and budget when choosing an income/mortgage protection insurance plan, as coverage and costs can vary depending on the type of policy, your age and health and many other factors.",
    title: 'Income/Mortgage Protection Insurance',
    tags: [],
    author: {
      name: 'Clara Bertoletti',
      avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
    },
    date: '04 Aug',
  },
  {
    image: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img1.jpg',
    description:
      "People who have financial obligations, such as a mortgage or any other debts, may need this type of insurance to help maintain their standard of living and meet their financial obligations in the event that they are unable to work due to a covered event, such as an illness or injury.",
      desc:"People who have a higher likelihood of being unable to work due to an illness or injury, such as those in physically demanding jobs or who have pre-existing medical conditions, may also benefit from income/mortgage protection insurance.",
      title: 'Do I need Income Protection Insurance?',
      tags: [],
      author: {
        name: 'Jhon Anderson',
        avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
      },
    date: '12 Sep',
  },
];

const PopularNews = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Income/Mortgage protection insurance
        </Typography>
        <Typography
          variant="h6"
          color={'text.secondary'}
          align={'left'}
          data-aos={'fade-up'}
        >
          Income protection and mortgage protection are types of insurance that provide financial protection in the event if you are unable to work or earn an income.
          <br/>
          The purpose of income/mortgage protection insurance is to help you maintain your standard of living and meet your financial obligations, such as paying your mortgage, in the event that you are unable to work due to a covered event.
          <br/>
          The insurance typically provides a replacement income as a regular payment, until you are able to return to work or until the policy term ends.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              borderRadius={0}
              boxShadow={0}
              display={'flex'}
              flexDirection={{
                xs: 'column',
                md: i % 2 === 0 ? 'row-reverse' : 'row',
              }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50%' },
                }}
              >
                <Box
                  component={'img'}
                  loading="lazy"
                  height={1}
                  width={1}
                  src={item.image}
                  alt="..."
                  sx={{
                    objectFit: 'cover',
                    maxHeight: 360,
                    borderRadius: 2,
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  paddingX: { xs: 1, sm: 2, md: 4 },
                  paddingY: { xs: 2, sm: 4 },
                  width: { xs: 1, md: '50%' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  {item.tags.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      component="a"
                      href=""
                      clickable
                      size={'small'}
                      color={'primary'}
                      sx={{ marginBottom: 1, marginRight: 1 }}
                    />
                  ))}
                </Box>
                <Typography
                  variant={'h6'}
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {item.title}
                </Typography>
                <Typography color="text.secondary">
                  {item.description}
                  <br/>
                  <br/>
                  {item.desc}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PopularNews;
